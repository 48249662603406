<template>
	<div class="ppt-editor">
		<iframe id="iframeBox" @load="iframeLoaded" :src="ifSrc" style="width: 100%; height: 100vh" :allowfullscreen="true"></iframe>
	</div>
</template>

<script>
import { Loading } from 'element-ui';
export default {
	name: 'pptTemplate',

	data() {
		return { loading: null, sendData: [], isLoaded: false };
	},
	computed: {
		ifSrc() {
			if (process.env.NODE_ENV === 'development') {
				return 'http://localhost:8080/';
			}
			return '/static/ppt';
		},
		// 准备就绪
		canSend() {
			return this.isLoaded && this.sendData?.length > 0;
		}
	},
	watch: {
		canSend(val) {
			if (val) {
				// 传给iframe
				const iframe = document.querySelector('#iframeBox');

				setTimeout(() => {
					iframe.contentWindow.postMessage({ content: this.sendData, type: 'done' }, '*');
				}, 0);
			}
		}
	},
	mounted() {
		window.addEventListener('message', this.getIframeData, false);
		this.getPptTemplate();
	},

	methods: {
		iframeLoaded() {
			if (!this.getDateBefore) {
				this.loading = Loading.service({ target: '.ppt-editor' });
			}
			this.isLoaded = true;
		},
		// 获取 报告详情 并传给iframe
		getPptTemplate() {
			// const loadding = Loading.service({ target: '.ppt-editor' });

			return this.$axios
				.post('/v1/internal/talent/getPptTemplateList', { request: {} })
				.then((res) => {
					this.loading?.close();
					this.getDateBefore = !this.loading;
					if (res.code) {
						return this.$message.error('接口错误');
					}

					this.sendData = res.list;
				})
				.catch(() => {
					this.loading?.close();
					this.getDateBefore = !this.loading;
				});
		},
		getIframeData(e) {
			const { type } = e.data;

			if (type) {
				switch (type) {
					case 'back':
						// this.$router.push({ path: '/useTalentCorrectly/talentEvaluation/evaluationResult' });
						this.$router.go(-1);
						break;
					case 'save':
						this.savePptTemplate(e);
						break;
					default:
						break;
				}
			}
		},
		// 保存模板
		savePptTemplate({ data }) {
			const loadding = Loading.service({ target: '.ppt-editor' });
			const loading = this.$message({ message: '保存中，请稍候。', duration: 0, type: 'warning' });

			this.$axios
				.post('/v1/internal/talent/createPptTemplate', { request: data.slides })
				.then((res) => {
					loadding.close();
					loading.close();
					if (res.code) {
						return this.$message.error('保存出错');
					}
					this.$message.success('保存成功');
					this.getPptTemplate();
				})
				.catch(() => {
					loadding.close();
					loading.close();
					this.$message.error('保存出错');
				});
		}
	},
	beforeDestroy() {
		window.removeEventListener('message', this.getIframeData);
	}
};
</script>

<style lang="scss" scoped></style>
